export function curry2(f) {
  return function(a) {
    return function(b) {
      return f(a, b);
    };
  };
}

export function curry3(f) {
  return function(a) {
    return function(b) {
      return function(c) {
        return f(a, b, c);
      };
    };
  };
}

export const round = (value, decimals = 0) => {
  if(value == null){
    return null;
  }
  const factor = 10 ** decimals;
  return Math.round(value * factor) / factor;
};

export const toUpperCaseFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const toLowerCaseFirst = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export const findById = (arr, id) => {
  if(arr == null || id == null){
    return null;
  }
  return arr.find( o => o?.id === id);
}

export const findIndexById = (arr, id) => {
  if(arr == null || id == null){
    return -1;
  }
  return arr.findIndex( o => o?.id === id );
}

export const removeItemFromArray = (arr, item) => {
  const i = arr?.indexOf(item);
  if(i >= 0) return arr.splice(i,1);
  return arr;
}

export const copyObject = (o) => {
  if(typeof o !== "object"){
    return o;
  }
  return JSON.parse(JSON.stringify(o));
}

export const isProperNumber = (arg) => {
  return (
    typeof arg === "number"
    && !Number.isNaN(arg)
    && arg !== Infinity
    && arg !== -Infinity)
}

export const isNonEmptyStr = (arg) => {
  return typeof arg === "string" && arg.length > 0;
}