import { withTranslation } from 'react-i18next';

import {
  Tooltip,
} from '@mui/material';
import {
  CalendarViewMonth,
  CircleOutlined,
  DoorFrontOutlined,
  HomeOutlined,
  HomeWorkOutlined,
  Remove,
  ShortTextOutlined,
  StairsOutlined,
  TabOutlined,
  VerticalAlignTop,
} from '@mui/icons-material/';

const IconComponent = {
  "no-location": Remove ,
  "top": VerticalAlignTop,
  "city": HomeWorkOutlined,
  "address": ShortTextOutlined,
  "building": HomeOutlined,
  "staircase": StairsOutlined,
  "floor": CalendarViewMonth,
  "apartment": DoorFrontOutlined,
  "room": TabOutlined,
  // "other": CircleOutlined // default, handled below
}

// Tooltip for the icon telling the name of the type
const TooltipWrapper =  withTranslation()(({t, children, disabled, type}) => {
  if(disabled){
    return <>{children}</>;
  }
  return <Tooltip title={`${t('type')}: ${t(type)}`}>{children}</Tooltip>;
})

// An element that translates type names to corresponding  icons. 
// type: typename, sx: styles, fontSize: size of the icon ('small' (def) | 'medium' | 'large'),
// color: color of the icon, tooltipDisabled: disables the tooltip 
export const LocationTypeIcon = ({type, sx, fontSize='small', color='DarkSlateGray', tooltipDisabled}) => {
  if(type == null){
    return false
  }

  const Icon = IconComponent[type] || CircleOutlined

  return (
    <TooltipWrapper type={type} disabled={tooltipDisabled}>
      <Icon sx={{...sx, color}} fontSize={fontSize} />
    </TooltipWrapper>
  )
}