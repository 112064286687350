import {useState, useEffect, useCallback} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { activateDevices } from '../../server/serverOperation.js';
import { selectAllDevices,  } from '../../data/devicesSlice.js';
import { selectLanguage,  } from "../../data/uiSlice.js";
import PhonenumberInput from "../Phonenumbers/PhonenumberInput.js";
import { ButtonSmall } from "../Button.js";
import { HelpText } from '../ReadMore.js';

import {
  AppBar, Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material/';
import TelegramIcon from '@mui/icons-material/Telegram';
import CloseIcon from '@mui/icons-material/Close';
import { Add } from "@mui/icons-material";
import useDataGridLocaleText from "../Locations/useDataGridLocaleText.js";
import DeviceTypeIcon from "../Icons/DeviceTypeIcon.js";
import Container from "@mui/material/Container";
import { DataGridPro } from '@mui/x-data-grid-pro';
import {Stack} from "@mui/material";
import useScreenSize from "../../utility/useScreenSize";
import {TimeZoneSelector} from "../Time/TimeZone";
import {TOP_LOCATION_IND} from "../Locations/locationTableSettings";
import {locationAdded} from "../../data/locationsSlice";
import logger from "../../utility/logger.mjs";

const TELEGRAM_BOT_URL = process.env.REACT_APP_TELEGRAM_BOT_URL;

const userTypes = ['superadmin']

// device table column definitions
const columns = [
  {
    field: 'type',
    headerName: '', //t('type'),
    width: 40,
    display: 'flex',
    align: 'center',
    // render device type as an icons
    renderCell: row =>  <DeviceTypeIcon type={row.value} />,
  },
  {
    field: 'name',
    headerName: 'name',
    flex: 2,
    editable: true,
  },
  {
    field: 'uuid',
    headerName: 'id',
    flex: 1.2,
    editable: false,
  },
];

const PureActivateDevicesDialog = ({t, openButtonType, preselectedDeviceIds=[]}) => {
    const dispatch = useDispatch();
    const language = useSelector(selectLanguage);
    const [superuserPhonenumber, setSuperuserPhonenumber] = useState({phonenumber: "", country: ""});
    const [newDevicesNames, setNewDevicesNames] = useState(null);
    const [utcZoneOffset, setUTCzoneOffset] = useState(0);
    const [pinCode, setPinCode] = useState("");
    const [pinInputColor, setPinInputColor] = useState("grey");
    const devices = useSelector(selectAllDevices).filter(device => !device.inUse);
    const [selectedDeviceIds, setSelectedDeviceIds] = useState(preselectedDeviceIds);
    const [openFirst, setOpenFirst] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);
    console.log("selectedDevices",selectedDeviceIds)

    const handlePinChange = (e) => {
      const newPin = e.target.value;
      const valueIsValid =
        newPin === ""
        || (/^[0-9]+$/.test(newPin) && newPin.length <= 6)
      if (valueIsValid){
        setPinCode(newPin);
        setPinInputColor(pinCodeColorByLength[newPin.length] ?? "red");
      }
    }

    const pinCodeColorByLength = {
      0: "grey",
      6: "green",
    }
    
    const handleNextClick = () => {
      const phonenumberIsOk =
        superuserPhonenumber.phonenumber.length > 0
        && superuserPhonenumber.country.length > 0;
      const pinCodeIsOk = pinCode.length === 6
      if(!phonenumberIsOk){
        toast.error(t("setup-number-error"), {
          toastId: "setup-number-error"
        });
      }
      if(!pinCodeIsOk){
        toast.error(t("pin-error"), {
          toastId: "pin-error"
        });
      }
      if(selectedDeviceIds.length === 0){
        toast.error(t("No devices selected!"), {
          toastId: "devices-not-selected-error"
        });
      }
      if(phonenumberIsOk && pinCodeIsOk && selectedDeviceIds.length > 0){
        // close all toasts
        toast.dismiss();
        setOpenSecond(true);
      }
    }

    const { mutate:mutateActivateDevices, isLoading:activateDevicesIsLoading } = useMutation(
      activateDevices,
      {
        onSuccess: (data) => {
          console.log(data)
        }
        //   const newLocationId = data.insertId;
        //   const addedLocation = {
        //     id: newLocationId,
        //     projectId,
        //     parentId: newParentLocationId,
        //     name: newLocationName,
        //     type: newLocationType,
        //     info: newLocationInfo,
        //   }
        //   const newPath = newParentLocationId !== TOP_LOCATION_IND
        //     ? apiRef.current.getRow(newParentLocationId).path.concat([newLocationId])
        //     : [newLocationId];
        //
        //   // FOR REDUX SLICE:
        //   dispatch(locationAdded(addedLocation));
        //   // FOR DATA GRID: update the row of the target location
        //   apiRef.current.updateRows([{...addedLocation, path: newPath}]);
        //
        //   setNewLocationName("");
        //   setNewLocationType(initLocationType);
        //   setNewLocationInfo("");
        //
        //   toast.success(`${t("location-added-info")}: ${newLocationName}`, {
        //     toastId: `added-${newLocationName}`
        //   });
        // },
        // onError: (error) => {
        //   toast.error(t("location-add-error"), {
        //     toastId: "location-add-error"
        //   });
        //   logger.error("addLocation", error);
        // }
      }
    );

    const openFirstDialog = () => {
      setOpenFirst(true);
      setOpenSecond(false);
    }

    const closeBothDialogs = () => {
      setOpenFirst(false);
      setOpenSecond(false);
    }

  const handleConfirmClick = () => {
      // update only selected device's names
    const relevantNewDevicesNames = Object.fromEntries(
      Object.entries(newDevicesNames).filter(([deviceId,_]) => selectedDeviceIds.include(deviceId))
    );
    mutateActivateDevices({
      deviceIds: selectedDeviceIds,
      pinCode,
      superuserPhonenumber: {...superuserPhonenumber, alarmCall:false, userType:'superadmin'},
      restPhonenumbers: [],
      newDevicesNames: relevantNewDevicesNames,
      utcZoneOffset
    })
    closeBothDialogs();
  }

    const OpenButton = ({type}) => {
      if(type === "plus"){
        return(
          <IconButton onClick={openFirstDialog}>
            <Add />
          </IconButton>
        )
      }
      return (
        <Button variant="outlined" onClick={openFirstDialog}>
          {t('activate-devices-dialog-button')}
        </Button>
      )
    }

  const deviceCount = devices.length;
    const headerHeight = 41;
    const rowHeight = 36;
  const tableHeight = Math.min(400, headerHeight + (deviceCount * rowHeight));

  // TODO: saving name to state resets table value for some reason
  // // When user edits a name of a device in the table cell, save it to state.
  const processNameUpdate = (updatedRow) => {
  //   const {id, name} = updatedRow;
  //   setNewDevicesNames(prevState => ({...prevState, [id]: name}));
     return updatedRow;
  }

  const handleProcessRowUpdateError = useCallback((error) => {
    console.log("handleProcessRowUpdateError",error);
  }, []);

  return (
    <>
      <OpenButton type={openButtonType} />
      <Dialog
        fullScreen
        open={openFirst}
        onClose={closeBothDialogs}
      >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeBothDialogs}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t("activate-devices")}
          </Typography>
        </Toolbar>
      </AppBar>
        <DialogContent >
          <Container maxWidth="sm" >
            {t("activate-devices-dialog-info-1")}
            <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("phonenumber")}</Typography>
            <PhonenumberInput
              number={superuserPhonenumber.phonenumber}
              setNewNumber={setSuperuserPhonenumber}
              useType={userTypes[0]}
              setUserType={() => {}}
              userTypes={userTypes}
              inDialog
              defalutCountry={language}
              disabled={activateDevicesIsLoading}
              />
            <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("pin-code")}</Typography>
            <p style={{maxWidth: "90%"}}>{t("setup-dialog-pin-text")}</p>
            <HelpText
                text={t("setup-dialog-info-2-more")}
                buttonStyle={{float:"right", marginTop: -65}}
                />
            <TextField
              id="newPin"
              sx={{ input: { color: pinInputColor }} }
              type="text"
              name="newPint"
              placeholder={t("create-new-pin")}
              value={pinCode}
              onChange={handlePinChange}
              size="small"
              disabled={activateDevicesIsLoading}
            />
            <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("time-zone")}</Typography>
            <TimeZoneSelector
              value={utcZoneOffset}
              onChange={setUTCzoneOffset}
              disabled={activateDevicesIsLoading}
            />
            <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("inactivated-devices")}</Typography>
            <Box sx={{height: tableHeight, mx: useScreenSize().windowIsNarrow ? -4 : 0 }} >
              <DataGridPro
                localeText={useDataGridLocaleText()}
                rows={devices}
                columns={columns}
                density='compact'
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSet) => {
                  setSelectedDeviceIds(newSet);
                }}
                rowSelectionModel={selectedDeviceIds}
                processRowUpdate={processNameUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                hideFooter
                disableColumnMenu
              />
            </Box>
          <Stack
            direction="row"
            spacing={3}
            sx={{
              m: 2,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <ButtonSmall
              width="26%"
              text="cancel"
              onClick={closeBothDialogs}
              isLoading={activateDevicesIsLoading}
            />
            <ButtonSmall
              width="26%"
              text="next"
              onClick={handleNextClick}
              isLoading={activateDevicesIsLoading}
            />
            </Stack>
          </Container>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSecond}
      >
        <DialogTitle color="secondary.main">
          {t("activate-devices")}
        </DialogTitle>
        <DialogContent>
          <p>{t("activate-devices-dialog-2-info-1")}</p>
          <DataGridPro
            localeText={useDataGridLocaleText()}
            density='compact'
            columns={columns}
            rows={devices.filter(device => selectedDeviceIds.includes(device.id))}
            hideFooter
          />
          <p>{t("phonenumber")}: <b>{superuserPhonenumber.phonenumber}</b> </p>
          <p>{t("activate-devices-dialog-2-info-2")}</p>
        </DialogContent>
        <DialogActions>
          <ButtonSmall
            margin={"-15px 10px 20px 0px"}
            width="26%"
            text="back"
            onClick={openFirstDialog}
            isLoading = { activateDevicesIsLoading }
          />
            <ButtonSmall
            margin={"-15px 10px 20px 0px"}
            width="26%"
            text="activate"
            onClick={handleConfirmClick}
            isLoading = { activateDevicesIsLoading }
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

/****************************** */

// const PureActivateDevicesDialog1 = ({t}) => {
//   const language = useSelector(selectLanguage);
//   const [newNumber, setNewNumber] = useState({phonenumber: "", country: ""});

//   const confirm = useConfirm();

//   const handleActivateClick = async (dialogCloseFunc) => {
//     const pinCodeIsOk = false;
//     const phonenumberIsOk = false;
//     if(!pinCodeIsOk){
//       alert = <AlertError text={"no-superadmin-confirm-warning"} title={"warning-deleting-superadmin-title"} />
//     }
//     else if(!phonenumberIsOk) {
//       alert = <AlertError text={"warning-deleting-oneself"} title={"warning-deleting-oneself-title"} />
//     }
//     // downgrading one self
//     else if(downgradingOneSelf) {
//       alert = <AlertError text={"warning-losing-admin-privileges"} title={"warning-losing-admin-privileges-title"} />
//     }
    
//     if(!alert){ // everything ok!
//       await handleSaveOk(dialogCloseFunc);
//       return;
//     }

//     const confirmationOk = await confirm({
//       title: "warning",
//       children: alert,
//       okButtonText: "ok",
//       cancelButtonText: "cancel"
//     });

//     if(confirmationOk){
//       if(noSuperadmin){
//         executeDropAllNumbers(deviceId);
//         logout();
//         return;
//       }
//       if(deletingOneSelf){
//         await handleSaveOk(dialogCloseFunc);
//         logout();
//         return;
//       }
//       if(downgradingOneSelf){
//         await handleSaveOk(dialogCloseFunc);
//         return;
//         //dispatch(currentDeviceSet(8));
//       }
//     } 
//   }
    
//   return (
//     <>
//       <RawDialog
//         data={{
//           title: <>{t("Activate devices")} </>,
//           openButtonText: "activate devices",
//           okButtonText: "activate",
//           okButtonFunc: handleActivateClick,
//           //okButtonIsLoading: mutateLocationsIsLoading,
//           //okButtonDisabled: name.length === 0,
//           cancelButtonText: "close",
//           //cancelButtonFunc: () => setOpen(false),
//           //cancelButtonIsLoading,
//           }}
//         >
//         {t("activate-devices-dialog-info-1")}
//           <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("phonenumber")}</Typography>
//           <PhonenumberInput 
//             phonenumber={newNumber.phonenumber}
//             setNewNumber={ setNewNumber } 
//             useType={userTypes[0]} 
//             setUserType={() => {}} 
//             userTypes={userTypes} 
//             inDialog
//             disableUserTypeSelect
//             defalutCountry={language}
//             />
//           <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("pin-code")}</Typography>
//           <p style={{maxWidth: "90%"}}>{t("setup-dialog-pin-text")}</p>
//           <HelpText 
//               text={t("setup-dialog-info-2-more")} 
//               buttonStyle={{float:"right", marginTop: -65}}
//               />
//           <TextField
//             id="newPin" 
//             //sx={{ input: { color: pinInputColor }} }
//             type="text" 
//             name="newPint"
//             placeholder={t("create-new-pin")}
//             //value={pin}
//             //onChange={ handlePinChange }
//             size="small"
//           />
//         <Box sx={{ height: 400, width: '100%' }}>
//         <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("unactivive-devices")}</Typography>
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         //pageSizeOptions={[5]}
//         checkboxSelection
//         disableRowSelectionOnClick
//       />
//     </Box>
//       </RawDialog>
//     </>
//   )
// }
const ActivateDevicesDialog = withTranslation()(PureActivateDevicesDialog);
export { 
  ActivateDevicesDialog 
};