import { useState, useEffect , PureComponent, useCallback, useRef  } from "react";
import { withTranslation } from 'react-i18next';
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { useCurrentPng } from "recharts-to-png";

import { useGetChartDesc } from "../queries/influxDesc.js";
import {
  LIMIT_TYPE,
  getInfluxDesc,
  strokeColor, 
  unit, 
  timeRanges
} from '../utility/influxChartConfig.mjs';
import { round } from '../utility/function.mjs';
import logger from '../utility/logger';
import { 
  selectCurrentDevice,
  selectCurrentActivationTime,
  selectCurrentUTCzoneOffset,
  selectCurrentReferenceValuesEnabled
} from "../data/devicesSlice.js";
import { 
  dataRangeSet, 
  selectTimeRange,
  timeRangeSet,
  selectLanguage,
  selectUnitSystem,
  selectHourCycle
} from "../data/uiSlice.js";
import { PrettyTime } from "./Time/PrettyTime.js";
import { fetchInfluxData } from "../server/serverOperation.js";
import { DownloadRaport } from './DownloadRaport.js'
import { DataList } from './DataList/DataList.js';
import TimeRangeButtons from './TimeRangeButtons.js';
import { AlertWarning } from './Alert.js';
import { formatTime } from "./Time/time.mjs";

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer
} from "recharts";

import { 
  Collapse,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  CircularProgress,
  IconButton,
  Button,
  Link,
  Typography,
} from '@mui/material/';
import { 
  ExpandMore, 
  ExpandLess, 
  PictureAsPdf,
} from "@mui/icons-material";

import {LEAKGUARD_DRY_LIMIT, LEAKGUARD_WET_LIMIT} from "../utility/deviceConfig.js";
import {ALARM_MESSAGE_TYPE} from "../utility/influxChartConfig.mjs";
import useScreenSize from "../utility/useScreenSize";
import DownloadCSV from "./DownloadCSV";

// Styling of ticks
class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g className="tick" transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} textAnchor="end" fill="#666" transform="rotate(-15)">
          {payload.value}
        </text>
      </g>
    );
  }
}

// Definition of tooltip
const PureCustomTooltip = ({t, active, payload, label = "", categoryName, referenceValue=null }) => {
  if(active && payload && payload.length) {
    const name = categoryName?.[payload[0].name] ?? payload[0].name;
    const value = payload[0].value;
    const unit = payload[0].unit;
    let valueText = `${t(name)}: ${value}${unit}`;
    if(payload[0]?.name === "leakSensorADValue"){
      const valueStr = value > LEAKGUARD_DRY_LIMIT
        ? t('dry')
        : value < LEAKGUARD_WET_LIMIT
          ? t('wet')
          : t('normal');
        valueText = `${t(name)}: ${valueStr}`;
    }
    const referenceValueText = referenceValue == null || isNaN(referenceValue) 
      ? "" 
      : <><br/>{t("reference-value")}: {referenceValue}{unit}</>
    const alarmInfo = () => {
      if(payload[0]?.payload?.msgType === ALARM_MESSAGE_TYPE){
        return <><br/>{t("alarm")} <WarningAmberOutlinedIcon color={"warning"} sx={{mb:-1}}/></>
      }
      return false;
    }
    return (
        <Box sx={{bgcolor: "background.default", border: "1px solid", borderColor: "secondary.main", color:"secondary.main", padding: "5px 7px", borderRadius: 1}}>
          {label} 
          <br/> 
          { valueText }
          { referenceValueText }
          { alarmInfo() }
        </Box>
    );
  }

  return null;
};
const CustomTooltip = withTranslation()(PureCustomTooltip);

const PureInfluxCharts = ({t}) => {
  const windowIsNarrow = useScreenSize().width < 340;
  const dispatch = useDispatch();
  const [queryCount, setQueryCount] = useState(0);
  const [charts, setCharts] = useState([]);
  const reportDownloadButtonRef = useRef();
  const deviceData = useSelector(selectCurrentDevice);
  const timeRange = useSelector(selectTimeRange) || false;
  const defaultTimeRangeIndex =  deviceData?.uiGeneralDesc?.defaultTimeRange || 0;
  const activationTime = useSelector(selectCurrentActivationTime);
  const showReferenceValues = useSelector(selectCurrentReferenceValuesEnabled);
  const language = useSelector(selectLanguage);
  const hourCycle = useSelector(selectHourCycle);
  const unitSystem = useSelector(selectUnitSystem);
  const offset = useSelector(selectCurrentUTCzoneOffset);
  const dateNow = formatTime(new Date().toISOString(), 'DATETIME_SHORT', language, hourCycle, offset);
  const units = unit[unitSystem];
  const {
    fields,
    fieldSettings,
    categoryName,
    alarmLimitTypes,
    referenceValues,
    alarmLimit,
    sensorRange,
  } = useGetChartDesc();

  // for chart images in pdf. Just horrible but hooks cannot be looped
  const [getPng0, { ref:ref0 }] = useCurrentPng();
  const [getPng1, { ref:ref1 }] = useCurrentPng();
  const [getPng2, { ref:ref2 }] = useCurrentPng();
  const [getPng3, { ref:ref3 }] = useCurrentPng();
  const [getPng4, { ref:ref4 }] = useCurrentPng();
  const [getPng5, { ref:ref5 }] = useCurrentPng();
  const [getPng6, { ref:ref6 }] = useCurrentPng();
  const [getPng7, { ref:ref7 }] = useCurrentPng();
  const [getPng8, { ref:ref8 }] = useCurrentPng();
  const [getPng9, { ref:ref9 }] = useCurrentPng();
  const getPngs = [getPng0, getPng1, getPng2, getPng3, getPng4, getPng5, getPng6, getPng7, getPng8, getPng9];
  const refs = [ ref0, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9 ];

  const referenceTime = deviceData.data.referenceValues?.time || "";
  const localisedReferenceValues = fields.reduce( (acc, field) => {
    const value = units[field].conv(referenceValues?.[field]);
    if(value !== undefined || !isNaN(value)){
      return {...acc, [field]: value}
    }
    return acc;
  }, {});

  const [showTimeRange, setShowTimeRange] = useState(false);
  const [showFields, setShowFields] = useState(fields);
  const handleField = (event, newFields) => {
    setShowFields(newFields);
  };
  const handleShowClick = (field) => {
    const newShowFields = showFields.includes(field) 
      ? showFields.filter( f => f !== field)
      : [...showFields, field]
    setShowFields(newShowFields);
  }

  const {bucket, idFieldName} = getInfluxDesc(deviceData.commTech);
  const queryParams = {
      bucket,
      idFieldName,
      deviceType: deviceData.type,
      deviceUUID: deviceData.uuid,
      fields,
      unitSystem
  };

  const { data, refetch, isLoading, status, isError, isSuccess } = useQuery(
    ("influxData" + timeRange?.name),
    async () => {
      if(activationTime == null){
        throw Error("device not activated: data not fetched");
      }
      let timeRangeTemp = JSON.parse(JSON.stringify(timeRange));
      if(!timeRange){
        timeRangeTemp = JSON.parse(JSON.stringify(timeRanges(referenceTime, activationTime)[defaultTimeRangeIndex]));
        dispatch(timeRangeSet(timeRanges(referenceTime, activationTime)[defaultTimeRangeIndex]));
      }
      // limit time range start to activation time
      if( new Date(activationTime) > new Date(timeRangeTemp.range.start) ){
        timeRangeTemp.range.start = activationTime;
      }
      if(queryParams && (timeRangeTemp?.range?.start.length > 0) && unitSystem){
        setQueryCount(queryCount+1);
        return await fetchInfluxData(queryParams, timeRangeTemp, unitSystem, language, hourCycle, offset);
      }
    }
  );

  dispatch(dataRangeSet({
    start: data?.[0]?.name, 
    end: data?.[data?.length - 1]?.name
  }));
  
  useEffect(() => {
    refetch();
    setCharts([])
  }, [timeRange, unitSystem, hourCycle]);

  // Calculate domain. If there is a preset domain (from UIDB.UIdeviceDesc), use it.
  // Otherwise, fit everything in the domain as nicely as possible
  const dynamicDomainFunction = field => ([dataMin, dataMax]) => {
    if(fieldSettings?.[field]?.domain?.fixedDomain){
      return fieldSettings[field].domain.fixedDomain;
    }
    let domainMin = dataMin;
    let domainMax = dataMax;
    // If low or high alarm limit exists, they must be in the domain.
    if(typeof alarmLimit?.[field]?.low === "number"){
      const localisedAlarmLimitLow = units[field].conv(alarmLimit[field].low);
      domainMin = Math.min(domainMin, localisedAlarmLimitLow);
    }
    if(typeof alarmLimit?.[field]?.high === "number"){
      const localisedAlarmLimitHigh = units[field].conv(alarmLimit[field].high);
      domainMax = Math.max(domainMax, localisedAlarmLimitHigh);
    }
    // If reference value is shown and exists, it must be in the domain
    if(alarmLimitTypes[field] === LIMIT_TYPE["relative"]){
      if(typeof referenceValues?.[field] === "number"){
        const localisedReferenceValue = units[field].conv(referenceValues?.[field])
        domainMin = Math.min(domainMin, localisedReferenceValue);
        domainMax = Math.max(domainMax, localisedReferenceValue);
      }
    }
    // Increase domain by 20 % or upto sensor range limits in order show everything nicely.
    // OR if useSensorMin or useSensorMax is set (in UIDB.UIdeviceDesc),
    // force domain limits to sensor range min/max.
    const increasePercent = 20.0;
    const range = domainMax - domainMin
    const domainIncrease = range/increasePercent;
    // Show decimal in y-axel, if domain range < 10
    const decimals = range < 10 ? 1 : 0;
    const localisedSensorMin = units[field].conv(sensorRange[field].min, decimals);
    const localisedSensorMax = units[field].conv(sensorRange[field].max, decimals);
    if(fieldSettings?.[field]?.domain?.useSensorMin){
      domainMin =  localisedSensorMin;
    }
    else {
      domainMin = Math.max(localisedSensorMin,round(domainMin - domainIncrease, decimals));
    }
    if(fieldSettings?.[field]?.domain?.useSensorMax){
      domainMax = localisedSensorMax
    }
    else {
      domainMax = Math.min(localisedSensorMax,round(domainMax + domainIncrease, decimals));
    }
    return [domainMin, domainMax];
  }

  const rebootTime = !!referenceTime
  ? <PrettyTime format={'timeDay'}>{referenceTime}</PrettyTime> 
  : "?"

  const rangeAndRebootData = () => {
    const start = data?.[0]?.name ?? "--";
    const end = data?.[data?.length - 1]?.name ?? "--";

    return [
      [t("time-range-start"), start],
      [t("time-range-end"), end],
    ].concat( showReferenceValues
      ? [[t("latest-reboot"), rebootTime]]
      : []);
  }

  // const generateRaportImages = async () => {
  //   logger.info("jeee1")
  //   if(isSuccess){
  //     let chartArr = [];
  //     for(let i = 0; i < fields.length; i++ ){
  //       const field = fields[i];
  //       logger.info("field-loop",field, fields, getPngs[i]);
  //       const png = await getPngs[i]();
  //       chartArr.push({field, image:png});
  //       //setCharts(charts.concat({field, image:png}));
  //     }
  //     logger.info("charts2",chartArr);
  //     setCharts(chartArr);
  //     //logger.info("chart", chart)
  //   }
  // }

  // useEffect(() => {
  //   if(charts.length > 0){
  //     reportDownloadButtonRef.current.click();
  //   }
  // }, [charts]);

  // Alert areas are array of objects {start,end}. Area starts, when message type
  // turns to ALARM_MESSAGE_TYPE, and when it turns to something else, the area ends.
  let alertAreas = []; // [{start, end},...]
  let prevPoint = {};
  if(data){
    for(let point of data){
      if(prevPoint?.msgType == null || prevPoint.msgType !== ALARM_MESSAGE_TYPE){
        if(point?.msgType === ALARM_MESSAGE_TYPE){
          alertAreas.push({start: point.name, end: point.name})
        }
      }
      else if(point?.msgType === ALARM_MESSAGE_TYPE){
        alertAreas[alertAreas.length - 1].end = point.name
      }
      prevPoint = point;
    }
  }

  const handleReport = useCallback(async () => {
    let chartArr = [];
    for(let i = 0; i < fields.length; i++ ){
      const field = fields[i];
      //logger.info("field-loop",field, fields, getPngs[i]);
      const png = await getPngs[i]();
      chartArr.push({field, image:png});
      //setCharts(charts.concat({field, image:png}));
    }
    //logger.info("charts2",chartArr);
    setCharts(chartArr);
    //logger.info("chart", chart)
  }, [getPngs]);

  const ticksFunction = (field) => {
    const ticks = fieldSettings?.[field]?.ticks;
    if(ticks == null || ticks.length === 0){
      return []
    }
    return ticks.map( row => row[0])
  }

  const getTickFormatterFunction = (field) => (tick) => {
    const ticks = fieldSettings?.[field]?.ticks;
    if(ticks == null || ticks.length === 0){
      return tick
    }
    return t(ticks.find( row => row[0] === tick)?.[1]) ?? tick
  }

  const influxChartField = (field, i) => {
    if(isLoading) {
      return <CircularProgress />;
    }
    if(isError || data == null || data?.length === 0){
      return <AlertWarning text={"no-data"} title={false} />
    }
    return (
        <ResponsiveContainer 
         height={210} 
         >
          <LineChart
            ref={refs[i]}
            data={data} 
            margin={{ left: 10, right: 10, bottom: 15, }}
            >
          <CartesianGrid 
            horizontal={field !== "leakSensorADValue"}
            />
          <Tooltip content={<CustomTooltip referenceValue={localisedReferenceValues?.[field]} categoryName={categoryName} />} />
          <YAxis 
            //hide={fieldSettings?.[field]?.hideYaxis ?? false}
            ticks={ticksFunction(field)}
            dataKey={field} 
            type="number" 
            axisLine={false} 
            tickLine={false}
            tickFormatter={getTickFormatterFunction(field)}
            unit={units[field].symbol ?? ""}
            domain={dynamicDomainFunction(field)}
            >
          </YAxis>
          <XAxis 
            dataKey="name" 
            tick={<CustomizedAxisTick />}>
            domain={null}
          </XAxis>
          <ReferenceLine // reference value line 
            y={units[field].conv(referenceValues?.[field])} 
            label={{ value: t('ref'), position: 'insideBottomLeft' }} 
            stroke="grey"  
            strokeDasharray="3 3" />
          {alertAreas.map( a => // vertical alarm areas
            <ReferenceArea
              key={a.start}
              x1={a.start} 
              x2={a.end} 
              fill="red" 
              fillOpacity=".12" 
              // strokeWidth={3}  // showing area with width of 0 ???
              // stroke="red" 
              // strokeOpacity=".12"
            />
            )
          }
          <ReferenceArea // low alarm limit area
            y2={units[field].conv(alarmLimit?.[field]?.low)} 
            fill="red" 
            fillOpacity=".12" 
            />
          <ReferenceArea  // high alarm limit area
            y1={units[field].conv(alarmLimit?.[field]?.high)} 
            fill="red" 
            fillOpacity=".12" 
            />
          <Line
            type="monotone"
            stroke={strokeColor[field] ?? "#808080"}
            strokeWidth={2}
            dot={false}
            dataKey={field}
            unit={units[field].symbol}
            />
          </LineChart>
        </ResponsiveContainer>
    )
  }

  if(activationTime == null){
    return <AlertWarning text={t("device-not-activated-warning")} title={false} />
  }

  return (
    <Box>
      <Box sx={{ textAlign:"right", clear:"both", margin:"15px 0px 15px 0px" }}>
        { charts.length === 0 &&
          <Box sx={{my:1}} >
            <Button
              onClick={ handleReport }
              variant="outlined" 
              disabled={ isLoading || isError || data?.length === 0 || data == null}
              endIcon={<PictureAsPdf />}
              >
              {t('generate-report')}
            </Button>
          </Box>
        }
        <Box sx={{my:1}}>
          <DownloadRaport 
            data={deviceData} 
            rebootTime={formatTime(referenceTime, 'DATETIME_SHORT', language, hourCycle, offset)}
            dateNow={dateNow}
            range={{start: data?.[0]?.name, end: data?.[data?.length - 1]?.name}} 
            charts={charts}
            referece={reportDownloadButtonRef}
            disabled={ isLoading || !isSuccess || charts.length === 0  }
          /> 
        </Box>
        <Box sx={{my:1}} >
          <DownloadCSV
            data={data}
            fileName={`${deviceData.name}-${dateNow}`}
            headers={fields}
            disabled={!(data?.length > 0)}
          />
        </Box>
      </Box>
        {/* <ToggleButtonGroup
        value={showFields}
        color="primary"
        onChange={handleField}
      >
        {fields.map ( (influxKey) =>
          <ToggleButton key={influxKey} value={influxKey}>
            {t(influxKey + "-abbreviation")}
          </ToggleButton>
        )} 
      </ToggleButtonGroup> <Box sx={{ mt: '1em',textAlign:"left"  }}> <h3 style={{ clear:"left",float: "left"}}>*/}
      <Box>
        <Typography 
          variant="h2"
          style={{ clear:"left",float: "left"}} 
          onClick={() => setShowTimeRange(!showTimeRange)}
        >
          {t("time-range")}: {t(timeRange.name)}
          </Typography>
        <IconButton 
          sx={{float: "left", mt: -1.1, clear:"right", display: windowIsNarrow ? "none" : "block"}}
          onClick={ () => setShowTimeRange(!showTimeRange)}
        >
          {showTimeRange ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <Collapse sx={{clear:"both"}} in={showTimeRange} timeout="auto" unmountOnExit>
          <Box sx={{width: 270, margin: 'auto'}}>
            <TimeRangeButtons />
            <DataList data={rangeAndRebootData()} />
          </Box>
        </Collapse>
      </Box>
      <Box sx={{ mt: '1em',marginBottom:10 }}>
      {fields.map( (field,i) => { return (
        <div key={field} >
        <Typography
          variant="h2"
          style={{ clear:"left",float: "left"}}
          onClick={() => handleShowClick(field)}
        >
          {t(categoryName?.[field])}
        </Typography>
          <IconButton
            sx={{float: "left", mt: -1.1, clear:"right"}}
            onClick={() => handleShowClick(field)}
          >
            {showFields.includes(field) ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Box sx={{ minHeight: 3, marginBottom:3 }}>
          <Collapse 
            sx={{clear:"both"}} 
            in={showFields.includes(field)} 
            timeout="auto" 
            unmountOnExit
            >
            <Box bgcolor="background.light" borderRadius={3} >
              {influxChartField(field, i)}
            </Box>
          </Collapse>
          </Box>
        </div>
      )})}
      </Box> 
    </Box>
  );
};
const InfluxCharts = withTranslation()(PureInfluxCharts);

export { InfluxCharts };