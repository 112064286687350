import {withTranslation} from "react-i18next";

import {Button} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

const DELIMITER = ";";

const PureDownloadCSVButton = ({t, data, headers, fileName, disabled }) => {
  const downloadCSV = () => {
    // Convert the data array into a CSV string
    const csvString = [
      ["Timestamp", ...headers],
      ...data.map(row => ["name", ...headers].map(header => row[header] ))
    ]
      .map(row => row.join(DELIMITER))
      .join("\n");

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv' });

    // Generate a download link and initiate the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'download.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Button
      onClick={ downloadCSV }
      variant="outlined"
      disabled={disabled}
      endIcon={<DownloadIcon />}
    >
      {t('generate-report-csv')}
    </Button>
  )
};
const DownloadCSVButton = withTranslation()(PureDownloadCSVButton);
export default DownloadCSVButton;