import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { 
  updateDeviceLocation,
  sendNewDeviceName,
  updateDevicesUTCzoneOffset,
} from '../../server/serverOperation'

import { 
  selectDeviceById,
  deviceNameSet,
  deviceUTCzoneOffsetSet
} from '../../data/devicesSlice';
import { selectCurrentProjectId, timeRangeSet, currentDeviceSet, brandSet, themeModeSet, currentPageSet } from '../../data/uiSlice';
import { 
  deviceMoved,
  selectLocationByDeviceId, 
} from '../../data/locationsSlice';

import logger from '../../utility/logger.mjs';

import {DataList} from '../DataList/DataList';
import TextInput from "../DataList/TextInput";
import {ButtonSmall} from '../Button';
import {RawLocationSelect} from '../Locations/LocationSelect';
import {PrettyTime, TimeLeftTo} from '../Time/PrettyTime';
import {TimeZoneSelector, TimeZone} from "../Time/TimeZone";
import DeviceActionButtons from './DeviceActionButtons';

import {
  Box,
  Card,
  CardContent,
  Input,
  Stack,
  Typography, 
} from '@mui/material/';
import DeviceTypeIcon from '../Icons/DeviceTypeIcon';
import DeviceStatusIcon from '../Icons/DeviceStatusIcon';
import {DEVICE_NAME_LENGTH_MAX} from "../../utility/deviceConfig";


const useEditModeSetter = (handleResetClick) => {
  const [editModeEnabled, setEditModeEnabledRaw] = useState(false);
  const setEditModeEnabled = () => {
    handleResetClick();
    setEditModeEnabledRaw(!editModeEnabled);
  }
  return {editModeEnabled, setEditModeEnabled}
}

// COMPONENT: List of info about given detail.
const PureDeviceDetails = ({t, deviceId, isInLocationTable, noCard, tableApiRef, hideActionButtons}) => {
  const dispatch = useDispatch();

  const rowId = `d${deviceId}`;
  const device = useSelector(selectDeviceById(deviceId));
  const locationId = useSelector(selectLocationByDeviceId(deviceId))?.id ?? -1;
  const projectId = useSelector(selectCurrentProjectId);

  const [newDeviceName, setNewDeviceName] = useState(device.name);
  const [newLocationId, setNewLocationId] = useState(locationId);
  const [newUTCzoneOffset, setNewUTCzoneOffset] = useState(device.utcZoneOffset);

  const handleResetClick = () => {
    setNewDeviceName(device.name);
    setNewLocationId(locationId);
    setNewUTCzoneOffset(device.utcZoneOffset);
  }
  const {editModeEnabled, setEditModeEnabled} = useEditModeSetter(handleResetClick);

  const { mutate:mutateDeviceLocation, isLoading:mutateDeviceLocationisLoading } = useMutation(
    updateDeviceLocation, // parameter {deviceId, locationId, projectId}
    {
      onSuccess: async () => {
        // update redux state
        dispatch(deviceMoved({
          locationId: newLocationId, 
          deviceId,
          projectId,
        }));
        if(isInLocationTable){
          // Construct path to the device and update the data grid row
          let path = [];
          if(newLocationId > 0){
            path = tableApiRef.current.getRow(newLocationId).path.concat([rowId]);
          }
          else{ // the target location is moved to the top
            path = [rowId];
          }
          tableApiRef.current.updateRows([{id:rowId, path}]);
        }
        toast.success(`${t("device-moved-info")}: ${newDeviceName}`, {
          toastId: `device-moved-info-${newDeviceName}`
        });
      },
      onError: (error) => {
        toast.error(error)
        logger.error(error);
      }
    }
  );

  const { mutate:mutateDeviceName, isLoading:mutateDeviceNameIsLoading } = useMutation(
    sendNewDeviceName, // parameter {deviceId, newName}
    {
      onSuccess: async (data) => {
        if(data.ok){
          // update redux state
          dispatch(deviceNameSet({deviceId, newName: newDeviceName}));
          if(isInLocationTable){
            // update data grid row
            tableApiRef.current.updateRows([{id: rowId, name: newDeviceName}]);
          }
          toast.success(`${t("device-name-updated-info")}: ${newDeviceName}`, {
            toastId: `device-name-updated-info-${newDeviceName}`
          });
        }
        else{
          toast.error(`${t("device-name-not-updated-error")}: ${device.name}`, {
            toastId: `device-name-not-updated-error-${newDeviceName}`
          });
        }
      },
      onError: (error) => {
        toast.error(`${t("device-name-not-updated-error")}: ${device.name}`, {
          toastId: `device-name-not-updated-error-${newDeviceName}`
        });
        logger.error(error);
      }
    }
  );

  const { mutate:mutateDeviceUTCzoneOffset, isLoading:mutateDeviceUTCzoneOffsetIsLoading } = useMutation(
    updateDevicesUTCzoneOffset, // parameter {deviceIds, newOffset}
    {
      onSuccess: async (data) => {
        if(data.ok){
          // update redux state
          dispatch(deviceUTCzoneOffsetSet({deviceId, newUTCzoneOffset}));
          if(isInLocationTable){
            // update data grid row
            tableApiRef.current.updateRows([{id: rowId, utcZoneOffset: newUTCzoneOffset}]);
          }
          toast.success(<>{`${t("device-utc-offset-updated-info")}: `} <TimeZone offset={newUTCzoneOffset} /></>, {
            toastId: `device-utc-offset-updated-info-${newUTCzoneOffset}`
          });
        }
        else{
          toast.error(<>{`${t("utc-offset-not-updated-error")}: `} <TimeZone offset={newUTCzoneOffset} /></>, {
            toastId: `utc-offset-not-updated-error-${newUTCzoneOffset}`
          });
        }
      },
      onError: (error) => {
        toast.error(<>{`${t("utc-offset-not-updated-error")}: `} <TimeZone offset={newUTCzoneOffset} /></>, {
          toastId: `utc-offset-not-updated-error-${newUTCzoneOffset}`
        });
        logger.error(error);
      }
    }
  );

  const handleDeviceSaveClick = () => {
    if(newDeviceName.length > 0){
      if(newDeviceName !== device.name){
        mutateDeviceName({deviceId, newName: newDeviceName});
      }
      if(newLocationId !== locationId){
        mutateDeviceLocation({deviceId, locationId: newLocationId, projectId})
      }
      if(newUTCzoneOffset !== device.utcZoneOffset){
        mutateDeviceUTCzoneOffset({deviceIds:[deviceId], newUTCzoneOffset})
      }
    }
    else {
      toast.warning(t("please-give-a-name"),{
        toastId: `please-give-a-name-${newDeviceName}`
      });
    }
  }

  const isLoading =
       mutateDeviceNameIsLoading
    || mutateDeviceLocationisLoading
    || mutateDeviceUTCzoneOffsetIsLoading;

  const timeLeft = device?.activationTime 
    ? <TimeLeftTo duration={{years: 2}}>{device.activationTime}</TimeLeftTo>
    : "?";
  const activationTime = device?.activationTime
    ? <PrettyTime format={"DATE_SHORT"}>{device.activationTime}</PrettyTime> 
    : "?";

  // Contents of a list of information about the device.
  // Array of rows with arrays of a header and conents.
  let deviceDataForList = [
    [
      t("name"),
      <TextInput
        value={newDeviceName}
        disabled={isLoading}
        textOnly={!editModeEnabled}
        onChange={e => setNewDeviceName(e.target.value)}
        multiline
        inputProps={{maxLength: DEVICE_NAME_LENGTH_MAX}}
      />
    ],
    [
      t("type"), 
      <Stack direction="row" spacing={1} sx={{alignItems: "center",}}>
        <div>{device.type}</div>
        <DeviceTypeIcon type={device.type} tooltipDisabled />
      </Stack>
    ],
    [
      t("status"),
      <Stack 
        spacing={1}
        direction="row"
      >
        <div>{device?.deviceStatus ?? t("unknown")}</div>
        <DeviceStatusIcon status={device?.deviceStatus} tooltipDisabled />
      </Stack>
    ],
    [
      t("uuid"), 
      device.uuid
    ],
    [
      t("activated"), 
      activationTime
    ],
    [
      t("time-left"), 
      timeLeft
    ],
    [
      t("time-zone"),
      <TimeZoneSelector
        value={newUTCzoneOffset}
        onChange={setNewUTCzoneOffset}
        valueOnly={!editModeEnabled}
        disabled={isLoading}
      />
    ],
    [
      t("your-privileges"), 
      t(device.privileges ?? 'no-privileges')
    ],
    [
      t("position"),
      <RawLocationSelect 
        value={editModeEnabled ? newLocationId : locationId} 
        onChange={e => setNewLocationId(e.target.value)} 
        deviceVariant
        disabled={isLoading}
        valueOnly={!editModeEnabled}
        />
    ]
  ]; 

  return (
    <Box sx={{width: '350px',}}>
      <Card variant="outlined" >
        <CardContent>
          <Stack 
            direction="row" 
            spacing={1} 
            sx={{
              mx:1,
              justifyContent: "space-between",
              alignItems: "center",
              }}
            >
            <Typography gutterBottom variant="h2" component="div">
              {t('device')}
            </Typography>
            <DeviceActionButtons 
              deviceId={deviceId} 
              hideSettingsButton={hideActionButtons}
              hideDataButton={hideActionButtons}
              setEditModeEnabled={setEditModeEnabled}
              editModeEnabled={editModeEnabled}
              />
        </Stack>
        <DataList 
              data={deviceDataForList}
              key={deviceId} 
            />
        </CardContent>
        { editModeEnabled && 
          <Stack direction="row" sx={{mb: 1, mt: -1, justifyContent: "space-around",}}>
            <ButtonSmall 
              sx={{m: 0}}
              text="clear" 
              onClick={handleResetClick} 
              disable={isLoading}
              />
            <ButtonSmall 
              sx={{m: 0}}
              text="save" 
              onClick={handleDeviceSaveClick}
              disable={isLoading}
              />
          </Stack>
        }
      </Card>
    </Box>
  )
}
const DeviceDetails = withTranslation()(PureDeviceDetails);

export default DeviceDetails;