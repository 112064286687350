import {useState} from 'react';
import {useSelector} from "react-redux";

import {selectCurrentUTCzoneOffset} from "../../data/devicesSlice";

import {MenuItem,Stack} from "@mui/material";
import {Duration} from "luxon";
import TextField from "@mui/material/TextField";

// options for offset parts
const hours = ['-12','-11','-10','-09','-08','-07','-06','-05','-04','-03','-02','-01','+00','+01','+02','+03','+04','+05','+06','+07','+08','+09','+10','+11','+12'];
const minutes = ['00','30','45'];

const offSetInputProps = {
  sx: {
    mb: -0.5,
    mx: 1,
    boxShadow: 'none',
    width: "4em",
  },
  inputProps: { IconComponent: () => null, style: { textAlign: 'center' },},
  size: "small",
  select: true,
  variant: "standard",
}

const convertToMinutes = (hours, minutes) => {
  return (hours * 60) + minutes;
}

export const TimeZoneSelector = ({value, onChange, disabled, valueOnly}) => {
  const offset = value ?? 0;
  const initialSign = offset < 0 ? "-" : "+";
  const formatedOffset = Duration.fromObject({ minutes: Math.abs(offset) }).rescale().toFormat("hhmm");
  const initialOffsetHours = initialSign + formatedOffset.slice(0,2);
  const initialOffsetMinutes = formatedOffset.slice(-2);
  const [inputHours, setInputHours] = useState(initialOffsetHours);
  const [inputMinutes, setInputMinutes] = useState(initialOffsetMinutes);

  const onHourChange = (e) => {
    setInputHours(e.target.value);
    onChange(convertToMinutes(Number(e.target.value), Number(inputMinutes)));
  }
  const onMinuteChange = (e) => {
    setInputMinutes(e.target.value);
    onChange(convertToMinutes(Number(inputHours), Number(e.target.value)));
  }

  if(valueOnly) {
    return <TimeZone offset={offset}/>
  }

  return (
    <Stack
      direction="row"
      sx={{alignItems :"center"}}
    >
      UTC
      <TextField
        {...offSetInputProps}
        onChange={onHourChange}
        value={inputHours}
        disabled={disabled}
      >
        {hours.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
      </TextField>
      :
      <TextField
        {...offSetInputProps}
        onChange={onMinuteChange}
        value={inputMinutes}
        disabled={disabled}
      >
        {minutes.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
      </TextField>
    </Stack>
  )
}

export const TimeZone = ({offset:offsetProp}) => {
  const currentOffset = useSelector(selectCurrentUTCzoneOffset);
  const offset = offsetProp ?? currentOffset;
  if(offset == null){
    return "UTC+00:00";
  }
  const sign = offset >= 0 ? "+" : "";
  const formatedOffset = Duration.fromObject({ minutes: offset }).rescale().toFormat("hh:mm");
  return "UTC" + sign + formatedOffset;
}